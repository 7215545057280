/* eslint-disable */
import * as types from './graphql';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n    query MasterAppointmentModal_MasterWorkPlaceQuery($id: Int!) {\n      masterWorkPlace(id: $id) {\n        id\n        workPeriods {\n          id\n          start: startedAt\n          end: endedAt\n        }\n        cartServices {\n          id\n          partnerService {\n            id\n            price\n            duration\n          }\n        }\n      }\n    }\n  ": types.MasterAppointmentModal_MasterWorkPlaceQueryDocument,
    "\n    mutation MasterAppointmentModal_AppointToMasterMutation(\n      $workPlaceId: Int!\n      $input: AppointInput!\n    ) {\n      appointToMaster(workPlaceId: $workPlaceId, input: $input) {\n        id\n      }\n    }\n  ": types.MasterAppointmentModal_AppointToMasterMutationDocument,
    "\n  fragment Links_AppointmentFragment on Appointment {\n    id\n    startedAt\n    endedAt\n    workPlace {\n      id\n      address\n      location\n      master {\n        id\n        fullName\n      }\n      company {\n        id\n        address\n      }\n    }\n    services {\n      id\n      service {\n        id\n        name\n      }\n    }\n  }\n": types.Links_AppointmentFragmentFragmentDoc,
    "\n    query CartBlock_CartMastersQuery {\n      cartMasters {\n        id\n        ...CartServiceMasterGroup_MasterFragment\n      }\n    }\n  ": types.CartBlock_CartMastersQueryDocument,
    "\n    query CartMasterBlock_MasterQuery($id: Int!) {\n      master(id: $id) {\n        id\n        workPlaces {\n          id\n          cartServices {\n            id\n          }\n          ...CartServiceWorkPlaceGroup_MasterWorkPlaceFragment\n        }\n      }\n    }\n  ": types.CartMasterBlock_MasterQueryDocument,
    "\n  fragment CartService_MasterWorkPlaceFragment on MasterWorkPlace {\n    id\n    city {\n      id\n      slug\n    }\n    master {\n      id\n      slug\n    }\n  }\n": types.CartService_MasterWorkPlaceFragmentFragmentDoc,
    "\n  fragment CartService_CartServiceFragment on CartService {\n    id\n    partnerService {\n      id\n      price\n      service {\n        id\n        slug\n        name\n      }\n    }\n  }\n": types.CartService_CartServiceFragmentFragmentDoc,
    "\n  fragment CartServiceMasterGroup_MasterFragment on Master {\n    id\n    slug\n    fullName\n    avatarUrl\n    workPlaces {\n      id\n      cartServices {\n        id\n        partnerService {\n          id\n          price\n          duration\n          service {\n            id\n            slug\n            name\n          }\n        }\n      }\n    }\n  }\n": types.CartServiceMasterGroup_MasterFragmentFragmentDoc,
    "\n  fragment CartServiceWorkPlaceDetailedGroup_MasterWorkPlaceFragment on MasterWorkPlace {\n    id\n    address\n    master {\n      id\n      slug\n      fullName\n      avatarUrl\n    }\n    company {\n      id\n      name\n    }\n    cartServices {\n      id\n      ...CartService_CartServiceFragment\n    }\n    ...CartService_MasterWorkPlaceFragment\n  }\n": types.CartServiceWorkPlaceDetailedGroup_MasterWorkPlaceFragmentFragmentDoc,
    "\n    mutation CartServiceWorkPlaceDetailedGroup_DeleteWorkPlaceServicesFromCartMutation(\n      $workPlaceId: Int!\n    ) {\n      deleteWorkPlaceServicesFromCart(workPlaceId: $workPlaceId)\n    }\n  ": types.CartServiceWorkPlaceDetailedGroup_DeleteWorkPlaceServicesFromCartMutationDocument,
    "\n    mutation CartServiceWorkPlaceDetailedGroup_DeleteCartServiceMutation(\n      $id: Int!\n    ) {\n      deleteCartService(id: $id)\n    }\n  ": types.CartServiceWorkPlaceDetailedGroup_DeleteCartServiceMutationDocument,
    "\n  fragment CartServiceWorkPlaceGroup_MasterWorkPlaceFragment on MasterWorkPlace {\n    id\n    address\n    city {\n      id\n      slug\n    }\n    master {\n      id\n      slug\n    }\n    company {\n      id\n      name\n    }\n    cartServices {\n      id\n      partnerService {\n        id\n        price\n        duration\n        service {\n          id\n          slug\n          name\n        }\n      }\n    }\n  }\n": types.CartServiceWorkPlaceGroup_MasterWorkPlaceFragmentFragmentDoc,
    "\n    query Cart_CartMastersQuery {\n      cartMasters {\n        id\n        workPlaces {\n          id\n          ...CartServiceWorkPlaceDetailedGroup_MasterWorkPlaceFragment\n        }\n      }\n    }\n  ": types.Cart_CartMastersQueryDocument,
    "\n    mutation DialogScreen_AttachChatPhotos($input: AttachPhotosInput!) {\n      attachChatPhotos(input: $input) {\n        id\n        url\n      }\n    }\n  ": types.DialogScreen_AttachChatPhotosDocument,
    "\n  fragment CompanyCard_CompanyFragment on Company {\n    id\n    name\n    slug\n    address\n    location\n    logoUrl\n    yearsOnMarket\n    reviewCount\n  }\n": types.CompanyCard_CompanyFragmentFragmentDoc,
    "\n    mutation CompanyCard_UpdateCompanyLikedState(\n      $id: Int!\n      $newIsLiked: Boolean!\n    ) {\n      updateCompanyLikedState(id: $id, newIsLiked: $newIsLiked) {\n        isLiked\n      }\n    }\n  ": types.CompanyCard_UpdateCompanyLikedStateDocument,
    "\n  fragment CompanyDetailsTabs_CompanyFragment on Company {\n    id\n    serviceCount\n    reviewCount\n    masterCount\n  }\n": types.CompanyDetailsTabs_CompanyFragmentFragmentDoc,
    "\n    query CompanyReviewsTab_CompanyQuery($id: Int!) {\n      company(id: $id) {\n        id\n        reviews {\n          id\n        }\n      }\n    }\n  ": types.CompanyReviewsTab_CompanyQueryDocument,
    "\n    query CompanyServicesTab_CompanyQuery($id: Int!) {\n      company(id: $id) {\n        id\n        services {\n          id\n        }\n      }\n    }\n  ": types.CompanyServicesTab_CompanyQueryDocument,
    "\n    mutation CompanyListItem_UpdateCompanyLikedState(\n      $id: Int!\n      $newIsLiked: Boolean!\n    ) {\n      updateCompanyLikedState(id: $id, newIsLiked: $newIsLiked) {\n        isLiked\n      }\n    }\n  ": types.CompanyListItem_UpdateCompanyLikedStateDocument,
    "\n    mutation CompanyListItem_ReportMasterMutation(\n      $id: Int!\n      $input: ReportInput!\n    ) {\n      reportCompany(id: $id, input: $input) {\n        id\n      }\n    }\n  ": types.CompanyListItem_ReportMasterMutationDocument,
    "\n    query AppointmentsBlock_AppointmentsQuery(\n      $startDate: DateTime!\n      $endDate: DateTime!\n    ) {\n      appointments(startDate: $startDate, endDate: $endDate) {\n        id\n        ...AppointmentsBlockItem_AppointmentFragment\n      }\n    }\n  ": types.AppointmentsBlock_AppointmentsQueryDocument,
    "\n  fragment AppointmentsBlockItem_AppointmentFragment on Appointment {\n    id\n    startedAt\n    endedAt\n    workPlace {\n      id\n      address\n      master {\n        id\n        fullName\n        avatarUrl\n        slug\n      }\n      company {\n        id\n        address\n        name\n      }\n    }\n    services {\n      id\n      service {\n        id\n        name\n      }\n    }\n  }\n": types.AppointmentsBlockItem_AppointmentFragmentFragmentDoc,
    "\n    query FreaturedServicesBlock_FeaturedPartnerServicesQuery {\n      featuredPartnerServices {\n        id\n        ...FeaturedServicesBlock_PartnerServiceFragment\n      }\n    }\n  ": types.FreaturedServicesBlock_FeaturedPartnerServicesQueryDocument,
    "\n  fragment FeaturedServicesBlock_PartnerServiceFragment on PartnerService {\n    id\n    service {\n      id\n      name\n      slug\n      specialization {\n        id\n      }\n    }\n    price\n    duration\n    description\n    masters {\n      id\n      fullName\n      avatarUrl\n    }\n  }\n": types.FeaturedServicesBlock_PartnerServiceFragmentFragmentDoc,
    "\n    query HomePartnerMap_MastersQuery {\n      masters {\n        data {\n          id\n          workPlaces {\n            id\n            address\n            location\n            city {\n              id\n              slug\n            }\n          }\n          ...MasterPreview_MasterFragment\n        }\n      }\n    }\n  ": types.HomePartnerMap_MastersQueryDocument,
    "\n  fragment MasterPreview_MasterFragment on Master {\n    id\n    slug\n    fullName\n    avatarUrl\n    isLiked\n    reviewCount\n    rating\n    services {\n      id\n      service {\n        id\n        name\n        specialization {\n          id\n        }\n      }\n    }\n    specializations {\n      id\n      yearsOfExperience\n      specialization {\n        id\n      }\n    }\n  }\n": types.MasterPreview_MasterFragmentFragmentDoc,
    "\n    mutation MasterPreview_UpdateMasterLikedStateMutation(\n      $id: Int!\n      $newIsLiked: Boolean!\n    ) {\n      updateMasterLikedState(id: $id, newIsLiked: $newIsLiked) {\n        id\n        isLiked\n      }\n    }\n  ": types.MasterPreview_UpdateMasterLikedStateMutationDocument,
    "\n    query NearbyMastersBlock_MastersQuery($address: String) {\n      masters(address: $address) {\n        data {\n          id\n          ...MasterPreview_MasterFragment\n        }\n      }\n    }\n  ": types.NearbyMastersBlock_MastersQueryDocument,
    "\n    query RecentBlogPostsBlock_BlogPostsQuery {\n      blogPosts {\n        id\n        ...RecentBlogPostsBlock_BlogPostFragment\n      }\n    }\n  ": types.RecentBlogPostsBlock_BlogPostsQueryDocument,
    "\n  fragment RecentBlogPostsBlock_BlogPostFragment on BlogPost {\n    id\n    slug\n    createdAt\n    title\n    coverUrl\n    shortText\n    viewCount\n    likeCount\n    commentCount\n  }\n": types.RecentBlogPostsBlock_BlogPostFragmentFragmentDoc,
    "\n  fragment FeaturedMasterPreview_MasterFragment on Master {\n    id\n    slug\n    avatarUrl\n    fullName\n    reviewCount\n    rating\n    workPlaces {\n      id\n      city {\n        id\n        slug\n      }\n    }\n    specializations {\n      specialization {\n        name\n      }\n    }\n  }\n": types.FeaturedMasterPreview_MasterFragmentFragmentDoc,
    "\n    query RecommendBlock_FeaturedMastersQuery {\n      featuredMasters {\n        id\n        ...FeaturedMasterPreview_MasterFragment\n      }\n    }\n  ": types.RecommendBlock_FeaturedMastersQueryDocument,
    "\n  fragment MasterBlogPost_BlogPostFragment on BlogPost {\n    id\n    slug\n    title\n    coverUrl\n    categories {\n      id\n      name\n    }\n  }\n": types.MasterBlogPost_BlogPostFragmentFragmentDoc,
    "\n    query MasterBlogPostsBlock_MasterQuery($id: Int!) {\n      master(id: $id) {\n        id\n        blogPosts {\n          id\n          ...MasterBlogPost_BlogPostFragment\n        }\n      }\n    }\n  ": types.MasterBlogPostsBlock_MasterQueryDocument,
    "\n  fragment MasterCard_MasterFragment on Master {\n    id\n    slug\n    fullName\n    phone\n    email\n    about\n    avatarUrl\n    rating\n    isLiked\n    reviewCount\n\n    ...SocialFields\n  }\n": types.MasterCard_MasterFragmentFragmentDoc,
    "\n    mutation MasterCard_UpdateMasterLikedState(\n      $id: Int!\n      $newIsLiked: Boolean!\n    ) {\n      updateMasterLikedState(id: $id, newIsLiked: $newIsLiked) {\n        id\n        isLiked\n      }\n    }\n  ": types.MasterCard_UpdateMasterLikedStateDocument,
    "\n  fragment MasterDetailsTabs_MasterFragment on Master {\n    id\n    serviceCount\n    reviewCount\n  }\n": types.MasterDetailsTabs_MasterFragmentFragmentDoc,
    "\n    query MasterExperienceTab_MasterQuery($id: Int!) {\n      master(id: $id) {\n        id\n        specializations {\n          id\n          yearsOfExperience\n          specialization {\n            id\n            name\n          }\n        }\n        experienceRecords {\n          id\n          type\n          name\n          startYear\n          endYear\n          certificatePhoto {\n            id\n            url\n          }\n        }\n      }\n    }\n  ": types.MasterExperienceTab_MasterQueryDocument,
    "\n    query MasterReviewsTab_MasterQuery($id: Int!) {\n      master(id: $id) {\n        id\n        reviews {\n          id\n          createdAt\n          text\n          rating\n          client {\n            id\n            fullName\n          }\n          attachedPhotos {\n            id\n            url\n          }\n        }\n      }\n    }\n  ": types.MasterReviewsTab_MasterQueryDocument,
    "\n    query MasterServicesTab_MasterQuery($id: Int!) {\n      master(id: $id) {\n        id\n        services {\n          id\n          ...PartnerGroupedServicePreviewList_PartnerServiceFragment\n        }\n      }\n    }\n  ": types.MasterServicesTab_MasterQueryDocument,
    "\n  fragment MasterHeaderPreview_MasterFragment on Master {\n    id\n    fullName\n    avatarUrl\n    rating\n    reviewCount\n    workPlaces {\n      id\n      address\n    }\n\n    instagramProfile\n    telegramProfile\n    whatsappProfile\n    vkProfile\n    youtubeProfile\n    personalSite\n  }\n": types.MasterHeaderPreview_MasterFragmentFragmentDoc,
    "\n    mutation MasterListItem_ReportMasterMutation(\n      $id: Int!\n      $input: ReportInput!\n    ) {\n      reportMaster(id: $id, input: $input) {\n        id\n      }\n    }\n  ": types.MasterListItem_ReportMasterMutationDocument,
    "\n    mutation MasterListItem_UpdateMasterLikedState(\n      $id: Int!\n      $newIsLiked: Boolean!\n    ) {\n      updateMasterLikedState(id: $id, newIsLiked: $newIsLiked) {\n        isLiked\n      }\n    }\n  ": types.MasterListItem_UpdateMasterLikedStateDocument,
    "\n  fragment MapMasterToPartner_MasterFragment on Master {\n    id\n    slug\n    fullName\n\n    ...SocialFields\n  }\n": types.MapMasterToPartner_MasterFragmentFragmentDoc,
    "\n    query AddressScreen_CityQuery($slug: String!) {\n      city(slug: $slug) {\n        id\n        metroStations {\n          id\n          name\n          color\n          location\n        }\n      }\n    }\n  ": types.AddressScreen_CityQueryDocument,
    "\n    query CatalogFiltersPreview_MetroStationQuery($id: Int!) {\n      metroStation(id: $id) {\n        id\n        name\n      }\n    }\n  ": types.CatalogFiltersPreview_MetroStationQueryDocument,
    "\n    query PartnerContextMenu_MasterQuery($id: Int!) {\n      master(id: $id) {\n        id\n        fullName\n      }\n    }\n  ": types.PartnerContextMenu_MasterQueryDocument,
    "\n    query PartnerContextMenu_CompanyQuery($id: Int!) {\n      company(id: $id) {\n        id\n        name\n      }\n    }\n  ": types.PartnerContextMenu_CompanyQueryDocument,
    "\n    mutation PartnerContextMenu_ReportMasterMutation(\n      $id: Int!\n      $input: ReportInput!\n    ) {\n      reportMaster(id: $id, input: $input) {\n        id\n      }\n    }\n  ": types.PartnerContextMenu_ReportMasterMutationDocument,
    "\n    mutation PartnerContextMenu_ReportCompanyMutation(\n      $id: Int!\n      $input: ReportInput!\n    ) {\n      reportCompany(id: $id, input: $input) {\n        id\n      }\n    }\n  ": types.PartnerContextMenu_ReportCompanyMutationDocument,
    "\n    mutation PartnerContextMenu_BlockUserMutation($id: Int!) {\n      blockUser(id: $id)\n    }\n  ": types.PartnerContextMenu_BlockUserMutationDocument,
    "\n    mutation PartnerContextMenu_BlockCompanyMutation($id: Int!) {\n      blockCompany(id: $id)\n    }\n  ": types.PartnerContextMenu_BlockCompanyMutationDocument,
    "\n    query PartnerWorkPlaceMap_MasterQuery($id: Int!) {\n      master(id: $id) {\n        id\n        workPlaces {\n          id\n          address\n          location\n          metroStations {\n            id\n            name\n            color\n            location\n          }\n          attachedPhotos {\n            id\n            url\n          }\n          company {\n            id\n            slug\n            name\n            city {\n              id\n              slug\n            }\n          }\n        }\n      }\n    }\n  ": types.PartnerWorkPlaceMap_MasterQueryDocument,
    "\n    query PartnerWorkPlaceMap_CompanyQuery($id: Int!) {\n      company(id: $id) {\n        id\n        name\n        location\n        attachedPhotos {\n          id\n          url\n        }\n        metroStations {\n          id\n          name\n          color\n          location\n        }\n      }\n    }\n  ": types.PartnerWorkPlaceMap_CompanyQueryDocument,
    "\n    query SearchResults_PartnerSearchQuery($query: String!) {\n      partnerSearch(query: $query) {\n        id\n        masters {\n          id\n          fullName\n          slug\n          rating\n          avatarUrl\n        }\n        companies {\n          id\n          name\n          slug\n          rating\n          logoUrl\n        }\n        specializations {\n          id\n          name\n          slug\n        }\n        services {\n          id\n          name\n          slug\n          specialization {\n            slug\n          }\n        }\n      }\n    }\n  ": types.SearchResults_PartnerSearchQueryDocument,
    "\n    query Details_MasterQuery($slug: String!) {\n      master(slug: $slug) {\n        id\n        fullName\n\n        ...MasterCard_MasterFragment\n        ...MasterDetailsTabs_MasterFragment\n        ...MasterHeaderPreview_MasterFragment\n      }\n    }\n  ": types.Details_MasterQueryDocument,
    "\n    query Details_CompanyQuery($slug: String!) {\n      company(slug: $slug) {\n        id\n        name\n\n        ...CompanyCard_CompanyFragment\n        ...CompanyDetailsTabs_CompanyFragment\n      }\n    }\n  ": types.Details_CompanyQueryDocument,
    "\n    mutation Details_ReportMasterMutation($id: Int!, $input: ReportInput!) {\n      reportMaster(id: $id, input: $input) {\n        id\n      }\n    }\n  ": types.Details_ReportMasterMutationDocument,
    "\n    mutation Details_ReportCompanyMutation($id: Int!, $input: ReportInput!) {\n      reportCompany(id: $id, input: $input) {\n        id\n      }\n    }\n  ": types.Details_ReportCompanyMutationDocument,
    "\n    query UserAboutScreen_UserQuery($id: Int!) {\n      user(id: $id) {\n        id\n        about\n      }\n    }\n  ": types.UserAboutScreen_UserQueryDocument,
    "\n    mutation UserAboutScreen_UpdateUser($id: Int!, $input: UpdateUserInput!) {\n      updateUser(id: $id, input: $input) {\n        id\n        about\n      }\n    }\n  ": types.UserAboutScreen_UpdateUserDocument,
    "\n    query UserNameScreen_UserQuery($id: Int!) {\n      user(id: $id) {\n        id\n        firstName\n        lastName\n      }\n    }\n  ": types.UserNameScreen_UserQueryDocument,
    "\n    mutation UserNameScreen_UpdateUser($id: Int!, $input: UpdateUserInput!) {\n      updateUser(id: $id, input: $input) {\n        id\n        fullName\n        firstName\n        lastName\n      }\n    }\n  ": types.UserNameScreen_UpdateUserDocument,
    "\n  fragment UserPersonalInfoScreen_User on User {\n    id\n    firstName\n    lastName\n    fullName\n    about\n    avatarUrl\n    profileFillPercentage\n\n    ...ContactFields\n  }\n": types.UserPersonalInfoScreen_UserFragmentDoc,
    "\n    mutation UpdateCurrentUserAvatar(\n      $id: Int!\n      $input: UpdateUserAvatarInput!\n    ) {\n      updateUserAvatar(id: $id, input: $input) {\n        id\n        avatarUrl\n      }\n    }\n  ": types.UpdateCurrentUserAvatarDocument,
    "\n    query UserPersonalInfoScreenGroup_UserQuery($id: Int!) {\n      user(id: $id) {\n        id\n        ...UserPersonalInfoScreen_User\n        ...ContactFields\n      }\n    }\n  ": types.UserPersonalInfoScreenGroup_UserQueryDocument,
    "\n    mutation UserPersonalInfoScreenGroup_UpdateUser(\n      $id: Int!\n      $input: UpdateUserInput!\n    ) {\n      updateUser(id: $id, input: $input) {\n        id\n        ...ContactFields\n      }\n    }\n  ": types.UserPersonalInfoScreenGroup_UpdateUserDocument,
    "\n    mutation Profile_DeleteUser($id: Int!) {\n      deleteUser(id: $id)\n    }\n  ": types.Profile_DeleteUserDocument,
    "\n  fragment PartnerGroupedServicePreview_PartnerServiceFragment on PartnerService {\n    id\n    ...PartnerServicePreviewList_PartnerServiceFragment\n  }\n": types.PartnerGroupedServicePreview_PartnerServiceFragmentFragmentDoc,
    "\n  fragment PartnerGroupedServicePreviewList_PartnerServiceFragment on PartnerService {\n    id\n    service {\n      id\n      specialization {\n        id\n        name\n      }\n    }\n    parameters {\n      id\n    }\n    ...PartnerGroupedServicePreview_PartnerServiceFragment\n  }\n": types.PartnerGroupedServicePreviewList_PartnerServiceFragmentFragmentDoc,
    "\n  fragment PartnerServicePreview_PartnerServiceFragment on PartnerService {\n    id\n    price\n    duration\n    description\n    isLiked\n    attachedPhotos {\n      id\n      url\n    }\n    service {\n      id\n      slug\n      name\n    }\n    masters {\n      id\n      slug\n    }\n    company {\n      id\n      slug\n    }\n    parameters {\n      id\n    }\n  }\n": types.PartnerServicePreview_PartnerServiceFragmentFragmentDoc,
    "\n    mutation MasterServicesTab_UpdatePartnerServiceLikedStateMutation(\n      $id: Int!\n      $newIsLiked: Boolean!\n    ) {\n      updatePartnerServiceLikedState(id: $id, newIsLiked: $newIsLiked) {\n        id\n        isLiked\n      }\n    }\n  ": types.MasterServicesTab_UpdatePartnerServiceLikedStateMutationDocument,
    "\n    mutation MasterServicesTab_AddMasterServiceToCartMutation(\n      $partnerServiceId: Int!\n      $workPlaceId: Int!\n      $input: AddServiceToCartInput!\n    ) {\n      addPartnerServiceToCart(\n        partnerServiceId: $partnerServiceId\n        workPlaceId: $workPlaceId\n        input: $input\n      ) {\n        id\n      }\n    }\n  ": types.MasterServicesTab_AddMasterServiceToCartMutationDocument,
    "\n  fragment PartnerServicePreviewList_PartnerServiceFragment on PartnerService {\n    id\n    parameters {\n      id\n    }\n    ...PartnerServicePreview_PartnerServiceFragment\n  }\n": types.PartnerServicePreviewList_PartnerServiceFragmentFragmentDoc,
    "\n    query PartnerService_MasterQuery(\n      $masterSlug: String!\n      $serviceSlug: String!\n    ) {\n      master(slug: $masterSlug) {\n        id\n        service(slug: $serviceSlug) {\n          id\n        }\n      }\n    }\n  ": types.PartnerService_MasterQueryDocument,
    "\n    query PartnerService_CompanyQuery(\n      $companySlug: String!\n      $serviceSlug: String!\n    ) {\n      company(slug: $companySlug) {\n        id\n      }\n    }\n  ": types.PartnerService_CompanyQueryDocument,
    "\n    mutation PartnerService_UpdatePartnerServiceLikedState(\n      $id: Int!\n      $newIsLiked: Boolean!\n    ) {\n      updatePartnerServiceLikedState(id: $id, newIsLiked: $newIsLiked) {\n        id\n        isLiked\n      }\n    }\n  ": types.PartnerService_UpdatePartnerServiceLikedStateDocument,
    "\n    query Services_SpecializationQuery($slug: String!) {\n      specialization(slug: $slug) {\n        id\n        name\n        slug\n        masterCount\n        companyCount\n\n        services {\n          id\n          slug\n          name\n        }\n      }\n    }\n  ": types.Services_SpecializationQueryDocument,
    "\n  fragment ContactFields on ContactEntity {\n    phone\n    email\n    instagramProfile\n    telegramProfile\n    whatsappProfile\n    youtubeProfile\n    vkProfile\n    odnoklassnikiProfile\n    personalSite\n  }\n": types.ContactFieldsFragmentDoc,
    "\n  fragment SocialFields on ContactEntity {\n    instagramProfile\n    telegramProfile\n    whatsappProfile\n    youtubeProfile\n    vkProfile\n    odnoklassnikiProfile\n    personalSite\n  }\n": types.SocialFieldsFragmentDoc,
    "\n    query AppLayoutCatalogMenu_SpecializationsQuery {\n      specializations {\n        id\n        slug\n        name\n        masterCount\n        companyCount\n        services {\n          id\n          slug\n          name\n        }\n      }\n    }\n  ": types.AppLayoutCatalogMenu_SpecializationsQueryDocument,
    "\n    query AppLayoutHeader_UserQuery($id: Int!) {\n      user(id: $id) {\n        id\n        avatarUrl\n\n        ...AppLayoutProfilePopover_UserFragment\n      }\n    }\n  ": types.AppLayoutHeader_UserQueryDocument,
    "\n  fragment AppLayoutProfilePopover_UserFragment on User {\n    id\n    fullName\n    avatarUrl\n  }\n": types.AppLayoutProfilePopover_UserFragmentFragmentDoc,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query MasterAppointmentModal_MasterWorkPlaceQuery($id: Int!) {\n      masterWorkPlace(id: $id) {\n        id\n        workPeriods {\n          id\n          start: startedAt\n          end: endedAt\n        }\n        cartServices {\n          id\n          partnerService {\n            id\n            price\n            duration\n          }\n        }\n      }\n    }\n  "): (typeof documents)["\n    query MasterAppointmentModal_MasterWorkPlaceQuery($id: Int!) {\n      masterWorkPlace(id: $id) {\n        id\n        workPeriods {\n          id\n          start: startedAt\n          end: endedAt\n        }\n        cartServices {\n          id\n          partnerService {\n            id\n            price\n            duration\n          }\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation MasterAppointmentModal_AppointToMasterMutation(\n      $workPlaceId: Int!\n      $input: AppointInput!\n    ) {\n      appointToMaster(workPlaceId: $workPlaceId, input: $input) {\n        id\n      }\n    }\n  "): (typeof documents)["\n    mutation MasterAppointmentModal_AppointToMasterMutation(\n      $workPlaceId: Int!\n      $input: AppointInput!\n    ) {\n      appointToMaster(workPlaceId: $workPlaceId, input: $input) {\n        id\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment Links_AppointmentFragment on Appointment {\n    id\n    startedAt\n    endedAt\n    workPlace {\n      id\n      address\n      location\n      master {\n        id\n        fullName\n      }\n      company {\n        id\n        address\n      }\n    }\n    services {\n      id\n      service {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment Links_AppointmentFragment on Appointment {\n    id\n    startedAt\n    endedAt\n    workPlace {\n      id\n      address\n      location\n      master {\n        id\n        fullName\n      }\n      company {\n        id\n        address\n      }\n    }\n    services {\n      id\n      service {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query CartBlock_CartMastersQuery {\n      cartMasters {\n        id\n        ...CartServiceMasterGroup_MasterFragment\n      }\n    }\n  "): (typeof documents)["\n    query CartBlock_CartMastersQuery {\n      cartMasters {\n        id\n        ...CartServiceMasterGroup_MasterFragment\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query CartMasterBlock_MasterQuery($id: Int!) {\n      master(id: $id) {\n        id\n        workPlaces {\n          id\n          cartServices {\n            id\n          }\n          ...CartServiceWorkPlaceGroup_MasterWorkPlaceFragment\n        }\n      }\n    }\n  "): (typeof documents)["\n    query CartMasterBlock_MasterQuery($id: Int!) {\n      master(id: $id) {\n        id\n        workPlaces {\n          id\n          cartServices {\n            id\n          }\n          ...CartServiceWorkPlaceGroup_MasterWorkPlaceFragment\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CartService_MasterWorkPlaceFragment on MasterWorkPlace {\n    id\n    city {\n      id\n      slug\n    }\n    master {\n      id\n      slug\n    }\n  }\n"): (typeof documents)["\n  fragment CartService_MasterWorkPlaceFragment on MasterWorkPlace {\n    id\n    city {\n      id\n      slug\n    }\n    master {\n      id\n      slug\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CartService_CartServiceFragment on CartService {\n    id\n    partnerService {\n      id\n      price\n      service {\n        id\n        slug\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CartService_CartServiceFragment on CartService {\n    id\n    partnerService {\n      id\n      price\n      service {\n        id\n        slug\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CartServiceMasterGroup_MasterFragment on Master {\n    id\n    slug\n    fullName\n    avatarUrl\n    workPlaces {\n      id\n      cartServices {\n        id\n        partnerService {\n          id\n          price\n          duration\n          service {\n            id\n            slug\n            name\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CartServiceMasterGroup_MasterFragment on Master {\n    id\n    slug\n    fullName\n    avatarUrl\n    workPlaces {\n      id\n      cartServices {\n        id\n        partnerService {\n          id\n          price\n          duration\n          service {\n            id\n            slug\n            name\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CartServiceWorkPlaceDetailedGroup_MasterWorkPlaceFragment on MasterWorkPlace {\n    id\n    address\n    master {\n      id\n      slug\n      fullName\n      avatarUrl\n    }\n    company {\n      id\n      name\n    }\n    cartServices {\n      id\n      ...CartService_CartServiceFragment\n    }\n    ...CartService_MasterWorkPlaceFragment\n  }\n"): (typeof documents)["\n  fragment CartServiceWorkPlaceDetailedGroup_MasterWorkPlaceFragment on MasterWorkPlace {\n    id\n    address\n    master {\n      id\n      slug\n      fullName\n      avatarUrl\n    }\n    company {\n      id\n      name\n    }\n    cartServices {\n      id\n      ...CartService_CartServiceFragment\n    }\n    ...CartService_MasterWorkPlaceFragment\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation CartServiceWorkPlaceDetailedGroup_DeleteWorkPlaceServicesFromCartMutation(\n      $workPlaceId: Int!\n    ) {\n      deleteWorkPlaceServicesFromCart(workPlaceId: $workPlaceId)\n    }\n  "): (typeof documents)["\n    mutation CartServiceWorkPlaceDetailedGroup_DeleteWorkPlaceServicesFromCartMutation(\n      $workPlaceId: Int!\n    ) {\n      deleteWorkPlaceServicesFromCart(workPlaceId: $workPlaceId)\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation CartServiceWorkPlaceDetailedGroup_DeleteCartServiceMutation(\n      $id: Int!\n    ) {\n      deleteCartService(id: $id)\n    }\n  "): (typeof documents)["\n    mutation CartServiceWorkPlaceDetailedGroup_DeleteCartServiceMutation(\n      $id: Int!\n    ) {\n      deleteCartService(id: $id)\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CartServiceWorkPlaceGroup_MasterWorkPlaceFragment on MasterWorkPlace {\n    id\n    address\n    city {\n      id\n      slug\n    }\n    master {\n      id\n      slug\n    }\n    company {\n      id\n      name\n    }\n    cartServices {\n      id\n      partnerService {\n        id\n        price\n        duration\n        service {\n          id\n          slug\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CartServiceWorkPlaceGroup_MasterWorkPlaceFragment on MasterWorkPlace {\n    id\n    address\n    city {\n      id\n      slug\n    }\n    master {\n      id\n      slug\n    }\n    company {\n      id\n      name\n    }\n    cartServices {\n      id\n      partnerService {\n        id\n        price\n        duration\n        service {\n          id\n          slug\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query Cart_CartMastersQuery {\n      cartMasters {\n        id\n        workPlaces {\n          id\n          ...CartServiceWorkPlaceDetailedGroup_MasterWorkPlaceFragment\n        }\n      }\n    }\n  "): (typeof documents)["\n    query Cart_CartMastersQuery {\n      cartMasters {\n        id\n        workPlaces {\n          id\n          ...CartServiceWorkPlaceDetailedGroup_MasterWorkPlaceFragment\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation DialogScreen_AttachChatPhotos($input: AttachPhotosInput!) {\n      attachChatPhotos(input: $input) {\n        id\n        url\n      }\n    }\n  "): (typeof documents)["\n    mutation DialogScreen_AttachChatPhotos($input: AttachPhotosInput!) {\n      attachChatPhotos(input: $input) {\n        id\n        url\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CompanyCard_CompanyFragment on Company {\n    id\n    name\n    slug\n    address\n    location\n    logoUrl\n    yearsOnMarket\n    reviewCount\n  }\n"): (typeof documents)["\n  fragment CompanyCard_CompanyFragment on Company {\n    id\n    name\n    slug\n    address\n    location\n    logoUrl\n    yearsOnMarket\n    reviewCount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation CompanyCard_UpdateCompanyLikedState(\n      $id: Int!\n      $newIsLiked: Boolean!\n    ) {\n      updateCompanyLikedState(id: $id, newIsLiked: $newIsLiked) {\n        isLiked\n      }\n    }\n  "): (typeof documents)["\n    mutation CompanyCard_UpdateCompanyLikedState(\n      $id: Int!\n      $newIsLiked: Boolean!\n    ) {\n      updateCompanyLikedState(id: $id, newIsLiked: $newIsLiked) {\n        isLiked\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CompanyDetailsTabs_CompanyFragment on Company {\n    id\n    serviceCount\n    reviewCount\n    masterCount\n  }\n"): (typeof documents)["\n  fragment CompanyDetailsTabs_CompanyFragment on Company {\n    id\n    serviceCount\n    reviewCount\n    masterCount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query CompanyReviewsTab_CompanyQuery($id: Int!) {\n      company(id: $id) {\n        id\n        reviews {\n          id\n        }\n      }\n    }\n  "): (typeof documents)["\n    query CompanyReviewsTab_CompanyQuery($id: Int!) {\n      company(id: $id) {\n        id\n        reviews {\n          id\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query CompanyServicesTab_CompanyQuery($id: Int!) {\n      company(id: $id) {\n        id\n        services {\n          id\n        }\n      }\n    }\n  "): (typeof documents)["\n    query CompanyServicesTab_CompanyQuery($id: Int!) {\n      company(id: $id) {\n        id\n        services {\n          id\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation CompanyListItem_UpdateCompanyLikedState(\n      $id: Int!\n      $newIsLiked: Boolean!\n    ) {\n      updateCompanyLikedState(id: $id, newIsLiked: $newIsLiked) {\n        isLiked\n      }\n    }\n  "): (typeof documents)["\n    mutation CompanyListItem_UpdateCompanyLikedState(\n      $id: Int!\n      $newIsLiked: Boolean!\n    ) {\n      updateCompanyLikedState(id: $id, newIsLiked: $newIsLiked) {\n        isLiked\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation CompanyListItem_ReportMasterMutation(\n      $id: Int!\n      $input: ReportInput!\n    ) {\n      reportCompany(id: $id, input: $input) {\n        id\n      }\n    }\n  "): (typeof documents)["\n    mutation CompanyListItem_ReportMasterMutation(\n      $id: Int!\n      $input: ReportInput!\n    ) {\n      reportCompany(id: $id, input: $input) {\n        id\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query AppointmentsBlock_AppointmentsQuery(\n      $startDate: DateTime!\n      $endDate: DateTime!\n    ) {\n      appointments(startDate: $startDate, endDate: $endDate) {\n        id\n        ...AppointmentsBlockItem_AppointmentFragment\n      }\n    }\n  "): (typeof documents)["\n    query AppointmentsBlock_AppointmentsQuery(\n      $startDate: DateTime!\n      $endDate: DateTime!\n    ) {\n      appointments(startDate: $startDate, endDate: $endDate) {\n        id\n        ...AppointmentsBlockItem_AppointmentFragment\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment AppointmentsBlockItem_AppointmentFragment on Appointment {\n    id\n    startedAt\n    endedAt\n    workPlace {\n      id\n      address\n      master {\n        id\n        fullName\n        avatarUrl\n        slug\n      }\n      company {\n        id\n        address\n        name\n      }\n    }\n    services {\n      id\n      service {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment AppointmentsBlockItem_AppointmentFragment on Appointment {\n    id\n    startedAt\n    endedAt\n    workPlace {\n      id\n      address\n      master {\n        id\n        fullName\n        avatarUrl\n        slug\n      }\n      company {\n        id\n        address\n        name\n      }\n    }\n    services {\n      id\n      service {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query FreaturedServicesBlock_FeaturedPartnerServicesQuery {\n      featuredPartnerServices {\n        id\n        ...FeaturedServicesBlock_PartnerServiceFragment\n      }\n    }\n  "): (typeof documents)["\n    query FreaturedServicesBlock_FeaturedPartnerServicesQuery {\n      featuredPartnerServices {\n        id\n        ...FeaturedServicesBlock_PartnerServiceFragment\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment FeaturedServicesBlock_PartnerServiceFragment on PartnerService {\n    id\n    service {\n      id\n      name\n      slug\n      specialization {\n        id\n      }\n    }\n    price\n    duration\n    description\n    masters {\n      id\n      fullName\n      avatarUrl\n    }\n  }\n"): (typeof documents)["\n  fragment FeaturedServicesBlock_PartnerServiceFragment on PartnerService {\n    id\n    service {\n      id\n      name\n      slug\n      specialization {\n        id\n      }\n    }\n    price\n    duration\n    description\n    masters {\n      id\n      fullName\n      avatarUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query HomePartnerMap_MastersQuery {\n      masters {\n        data {\n          id\n          workPlaces {\n            id\n            address\n            location\n            city {\n              id\n              slug\n            }\n          }\n          ...MasterPreview_MasterFragment\n        }\n      }\n    }\n  "): (typeof documents)["\n    query HomePartnerMap_MastersQuery {\n      masters {\n        data {\n          id\n          workPlaces {\n            id\n            address\n            location\n            city {\n              id\n              slug\n            }\n          }\n          ...MasterPreview_MasterFragment\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MasterPreview_MasterFragment on Master {\n    id\n    slug\n    fullName\n    avatarUrl\n    isLiked\n    reviewCount\n    rating\n    services {\n      id\n      service {\n        id\n        name\n        specialization {\n          id\n        }\n      }\n    }\n    specializations {\n      id\n      yearsOfExperience\n      specialization {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment MasterPreview_MasterFragment on Master {\n    id\n    slug\n    fullName\n    avatarUrl\n    isLiked\n    reviewCount\n    rating\n    services {\n      id\n      service {\n        id\n        name\n        specialization {\n          id\n        }\n      }\n    }\n    specializations {\n      id\n      yearsOfExperience\n      specialization {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation MasterPreview_UpdateMasterLikedStateMutation(\n      $id: Int!\n      $newIsLiked: Boolean!\n    ) {\n      updateMasterLikedState(id: $id, newIsLiked: $newIsLiked) {\n        id\n        isLiked\n      }\n    }\n  "): (typeof documents)["\n    mutation MasterPreview_UpdateMasterLikedStateMutation(\n      $id: Int!\n      $newIsLiked: Boolean!\n    ) {\n      updateMasterLikedState(id: $id, newIsLiked: $newIsLiked) {\n        id\n        isLiked\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query NearbyMastersBlock_MastersQuery($address: String) {\n      masters(address: $address) {\n        data {\n          id\n          ...MasterPreview_MasterFragment\n        }\n      }\n    }\n  "): (typeof documents)["\n    query NearbyMastersBlock_MastersQuery($address: String) {\n      masters(address: $address) {\n        data {\n          id\n          ...MasterPreview_MasterFragment\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query RecentBlogPostsBlock_BlogPostsQuery {\n      blogPosts {\n        id\n        ...RecentBlogPostsBlock_BlogPostFragment\n      }\n    }\n  "): (typeof documents)["\n    query RecentBlogPostsBlock_BlogPostsQuery {\n      blogPosts {\n        id\n        ...RecentBlogPostsBlock_BlogPostFragment\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment RecentBlogPostsBlock_BlogPostFragment on BlogPost {\n    id\n    slug\n    createdAt\n    title\n    coverUrl\n    shortText\n    viewCount\n    likeCount\n    commentCount\n  }\n"): (typeof documents)["\n  fragment RecentBlogPostsBlock_BlogPostFragment on BlogPost {\n    id\n    slug\n    createdAt\n    title\n    coverUrl\n    shortText\n    viewCount\n    likeCount\n    commentCount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment FeaturedMasterPreview_MasterFragment on Master {\n    id\n    slug\n    avatarUrl\n    fullName\n    reviewCount\n    rating\n    workPlaces {\n      id\n      city {\n        id\n        slug\n      }\n    }\n    specializations {\n      specialization {\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment FeaturedMasterPreview_MasterFragment on Master {\n    id\n    slug\n    avatarUrl\n    fullName\n    reviewCount\n    rating\n    workPlaces {\n      id\n      city {\n        id\n        slug\n      }\n    }\n    specializations {\n      specialization {\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query RecommendBlock_FeaturedMastersQuery {\n      featuredMasters {\n        id\n        ...FeaturedMasterPreview_MasterFragment\n      }\n    }\n  "): (typeof documents)["\n    query RecommendBlock_FeaturedMastersQuery {\n      featuredMasters {\n        id\n        ...FeaturedMasterPreview_MasterFragment\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MasterBlogPost_BlogPostFragment on BlogPost {\n    id\n    slug\n    title\n    coverUrl\n    categories {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  fragment MasterBlogPost_BlogPostFragment on BlogPost {\n    id\n    slug\n    title\n    coverUrl\n    categories {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query MasterBlogPostsBlock_MasterQuery($id: Int!) {\n      master(id: $id) {\n        id\n        blogPosts {\n          id\n          ...MasterBlogPost_BlogPostFragment\n        }\n      }\n    }\n  "): (typeof documents)["\n    query MasterBlogPostsBlock_MasterQuery($id: Int!) {\n      master(id: $id) {\n        id\n        blogPosts {\n          id\n          ...MasterBlogPost_BlogPostFragment\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MasterCard_MasterFragment on Master {\n    id\n    slug\n    fullName\n    phone\n    email\n    about\n    avatarUrl\n    rating\n    isLiked\n    reviewCount\n\n    ...SocialFields\n  }\n"): (typeof documents)["\n  fragment MasterCard_MasterFragment on Master {\n    id\n    slug\n    fullName\n    phone\n    email\n    about\n    avatarUrl\n    rating\n    isLiked\n    reviewCount\n\n    ...SocialFields\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation MasterCard_UpdateMasterLikedState(\n      $id: Int!\n      $newIsLiked: Boolean!\n    ) {\n      updateMasterLikedState(id: $id, newIsLiked: $newIsLiked) {\n        id\n        isLiked\n      }\n    }\n  "): (typeof documents)["\n    mutation MasterCard_UpdateMasterLikedState(\n      $id: Int!\n      $newIsLiked: Boolean!\n    ) {\n      updateMasterLikedState(id: $id, newIsLiked: $newIsLiked) {\n        id\n        isLiked\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MasterDetailsTabs_MasterFragment on Master {\n    id\n    serviceCount\n    reviewCount\n  }\n"): (typeof documents)["\n  fragment MasterDetailsTabs_MasterFragment on Master {\n    id\n    serviceCount\n    reviewCount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query MasterExperienceTab_MasterQuery($id: Int!) {\n      master(id: $id) {\n        id\n        specializations {\n          id\n          yearsOfExperience\n          specialization {\n            id\n            name\n          }\n        }\n        experienceRecords {\n          id\n          type\n          name\n          startYear\n          endYear\n          certificatePhoto {\n            id\n            url\n          }\n        }\n      }\n    }\n  "): (typeof documents)["\n    query MasterExperienceTab_MasterQuery($id: Int!) {\n      master(id: $id) {\n        id\n        specializations {\n          id\n          yearsOfExperience\n          specialization {\n            id\n            name\n          }\n        }\n        experienceRecords {\n          id\n          type\n          name\n          startYear\n          endYear\n          certificatePhoto {\n            id\n            url\n          }\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query MasterReviewsTab_MasterQuery($id: Int!) {\n      master(id: $id) {\n        id\n        reviews {\n          id\n          createdAt\n          text\n          rating\n          client {\n            id\n            fullName\n          }\n          attachedPhotos {\n            id\n            url\n          }\n        }\n      }\n    }\n  "): (typeof documents)["\n    query MasterReviewsTab_MasterQuery($id: Int!) {\n      master(id: $id) {\n        id\n        reviews {\n          id\n          createdAt\n          text\n          rating\n          client {\n            id\n            fullName\n          }\n          attachedPhotos {\n            id\n            url\n          }\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query MasterServicesTab_MasterQuery($id: Int!) {\n      master(id: $id) {\n        id\n        services {\n          id\n          ...PartnerGroupedServicePreviewList_PartnerServiceFragment\n        }\n      }\n    }\n  "): (typeof documents)["\n    query MasterServicesTab_MasterQuery($id: Int!) {\n      master(id: $id) {\n        id\n        services {\n          id\n          ...PartnerGroupedServicePreviewList_PartnerServiceFragment\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MasterHeaderPreview_MasterFragment on Master {\n    id\n    fullName\n    avatarUrl\n    rating\n    reviewCount\n    workPlaces {\n      id\n      address\n    }\n\n    instagramProfile\n    telegramProfile\n    whatsappProfile\n    vkProfile\n    youtubeProfile\n    personalSite\n  }\n"): (typeof documents)["\n  fragment MasterHeaderPreview_MasterFragment on Master {\n    id\n    fullName\n    avatarUrl\n    rating\n    reviewCount\n    workPlaces {\n      id\n      address\n    }\n\n    instagramProfile\n    telegramProfile\n    whatsappProfile\n    vkProfile\n    youtubeProfile\n    personalSite\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation MasterListItem_ReportMasterMutation(\n      $id: Int!\n      $input: ReportInput!\n    ) {\n      reportMaster(id: $id, input: $input) {\n        id\n      }\n    }\n  "): (typeof documents)["\n    mutation MasterListItem_ReportMasterMutation(\n      $id: Int!\n      $input: ReportInput!\n    ) {\n      reportMaster(id: $id, input: $input) {\n        id\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation MasterListItem_UpdateMasterLikedState(\n      $id: Int!\n      $newIsLiked: Boolean!\n    ) {\n      updateMasterLikedState(id: $id, newIsLiked: $newIsLiked) {\n        isLiked\n      }\n    }\n  "): (typeof documents)["\n    mutation MasterListItem_UpdateMasterLikedState(\n      $id: Int!\n      $newIsLiked: Boolean!\n    ) {\n      updateMasterLikedState(id: $id, newIsLiked: $newIsLiked) {\n        isLiked\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MapMasterToPartner_MasterFragment on Master {\n    id\n    slug\n    fullName\n\n    ...SocialFields\n  }\n"): (typeof documents)["\n  fragment MapMasterToPartner_MasterFragment on Master {\n    id\n    slug\n    fullName\n\n    ...SocialFields\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query AddressScreen_CityQuery($slug: String!) {\n      city(slug: $slug) {\n        id\n        metroStations {\n          id\n          name\n          color\n          location\n        }\n      }\n    }\n  "): (typeof documents)["\n    query AddressScreen_CityQuery($slug: String!) {\n      city(slug: $slug) {\n        id\n        metroStations {\n          id\n          name\n          color\n          location\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query CatalogFiltersPreview_MetroStationQuery($id: Int!) {\n      metroStation(id: $id) {\n        id\n        name\n      }\n    }\n  "): (typeof documents)["\n    query CatalogFiltersPreview_MetroStationQuery($id: Int!) {\n      metroStation(id: $id) {\n        id\n        name\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query PartnerContextMenu_MasterQuery($id: Int!) {\n      master(id: $id) {\n        id\n        fullName\n      }\n    }\n  "): (typeof documents)["\n    query PartnerContextMenu_MasterQuery($id: Int!) {\n      master(id: $id) {\n        id\n        fullName\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query PartnerContextMenu_CompanyQuery($id: Int!) {\n      company(id: $id) {\n        id\n        name\n      }\n    }\n  "): (typeof documents)["\n    query PartnerContextMenu_CompanyQuery($id: Int!) {\n      company(id: $id) {\n        id\n        name\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation PartnerContextMenu_ReportMasterMutation(\n      $id: Int!\n      $input: ReportInput!\n    ) {\n      reportMaster(id: $id, input: $input) {\n        id\n      }\n    }\n  "): (typeof documents)["\n    mutation PartnerContextMenu_ReportMasterMutation(\n      $id: Int!\n      $input: ReportInput!\n    ) {\n      reportMaster(id: $id, input: $input) {\n        id\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation PartnerContextMenu_ReportCompanyMutation(\n      $id: Int!\n      $input: ReportInput!\n    ) {\n      reportCompany(id: $id, input: $input) {\n        id\n      }\n    }\n  "): (typeof documents)["\n    mutation PartnerContextMenu_ReportCompanyMutation(\n      $id: Int!\n      $input: ReportInput!\n    ) {\n      reportCompany(id: $id, input: $input) {\n        id\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation PartnerContextMenu_BlockUserMutation($id: Int!) {\n      blockUser(id: $id)\n    }\n  "): (typeof documents)["\n    mutation PartnerContextMenu_BlockUserMutation($id: Int!) {\n      blockUser(id: $id)\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation PartnerContextMenu_BlockCompanyMutation($id: Int!) {\n      blockCompany(id: $id)\n    }\n  "): (typeof documents)["\n    mutation PartnerContextMenu_BlockCompanyMutation($id: Int!) {\n      blockCompany(id: $id)\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query PartnerWorkPlaceMap_MasterQuery($id: Int!) {\n      master(id: $id) {\n        id\n        workPlaces {\n          id\n          address\n          location\n          metroStations {\n            id\n            name\n            color\n            location\n          }\n          attachedPhotos {\n            id\n            url\n          }\n          company {\n            id\n            slug\n            name\n            city {\n              id\n              slug\n            }\n          }\n        }\n      }\n    }\n  "): (typeof documents)["\n    query PartnerWorkPlaceMap_MasterQuery($id: Int!) {\n      master(id: $id) {\n        id\n        workPlaces {\n          id\n          address\n          location\n          metroStations {\n            id\n            name\n            color\n            location\n          }\n          attachedPhotos {\n            id\n            url\n          }\n          company {\n            id\n            slug\n            name\n            city {\n              id\n              slug\n            }\n          }\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query PartnerWorkPlaceMap_CompanyQuery($id: Int!) {\n      company(id: $id) {\n        id\n        name\n        location\n        attachedPhotos {\n          id\n          url\n        }\n        metroStations {\n          id\n          name\n          color\n          location\n        }\n      }\n    }\n  "): (typeof documents)["\n    query PartnerWorkPlaceMap_CompanyQuery($id: Int!) {\n      company(id: $id) {\n        id\n        name\n        location\n        attachedPhotos {\n          id\n          url\n        }\n        metroStations {\n          id\n          name\n          color\n          location\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query SearchResults_PartnerSearchQuery($query: String!) {\n      partnerSearch(query: $query) {\n        id\n        masters {\n          id\n          fullName\n          slug\n          rating\n          avatarUrl\n        }\n        companies {\n          id\n          name\n          slug\n          rating\n          logoUrl\n        }\n        specializations {\n          id\n          name\n          slug\n        }\n        services {\n          id\n          name\n          slug\n          specialization {\n            slug\n          }\n        }\n      }\n    }\n  "): (typeof documents)["\n    query SearchResults_PartnerSearchQuery($query: String!) {\n      partnerSearch(query: $query) {\n        id\n        masters {\n          id\n          fullName\n          slug\n          rating\n          avatarUrl\n        }\n        companies {\n          id\n          name\n          slug\n          rating\n          logoUrl\n        }\n        specializations {\n          id\n          name\n          slug\n        }\n        services {\n          id\n          name\n          slug\n          specialization {\n            slug\n          }\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query Details_MasterQuery($slug: String!) {\n      master(slug: $slug) {\n        id\n        fullName\n\n        ...MasterCard_MasterFragment\n        ...MasterDetailsTabs_MasterFragment\n        ...MasterHeaderPreview_MasterFragment\n      }\n    }\n  "): (typeof documents)["\n    query Details_MasterQuery($slug: String!) {\n      master(slug: $slug) {\n        id\n        fullName\n\n        ...MasterCard_MasterFragment\n        ...MasterDetailsTabs_MasterFragment\n        ...MasterHeaderPreview_MasterFragment\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query Details_CompanyQuery($slug: String!) {\n      company(slug: $slug) {\n        id\n        name\n\n        ...CompanyCard_CompanyFragment\n        ...CompanyDetailsTabs_CompanyFragment\n      }\n    }\n  "): (typeof documents)["\n    query Details_CompanyQuery($slug: String!) {\n      company(slug: $slug) {\n        id\n        name\n\n        ...CompanyCard_CompanyFragment\n        ...CompanyDetailsTabs_CompanyFragment\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation Details_ReportMasterMutation($id: Int!, $input: ReportInput!) {\n      reportMaster(id: $id, input: $input) {\n        id\n      }\n    }\n  "): (typeof documents)["\n    mutation Details_ReportMasterMutation($id: Int!, $input: ReportInput!) {\n      reportMaster(id: $id, input: $input) {\n        id\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation Details_ReportCompanyMutation($id: Int!, $input: ReportInput!) {\n      reportCompany(id: $id, input: $input) {\n        id\n      }\n    }\n  "): (typeof documents)["\n    mutation Details_ReportCompanyMutation($id: Int!, $input: ReportInput!) {\n      reportCompany(id: $id, input: $input) {\n        id\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query UserAboutScreen_UserQuery($id: Int!) {\n      user(id: $id) {\n        id\n        about\n      }\n    }\n  "): (typeof documents)["\n    query UserAboutScreen_UserQuery($id: Int!) {\n      user(id: $id) {\n        id\n        about\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation UserAboutScreen_UpdateUser($id: Int!, $input: UpdateUserInput!) {\n      updateUser(id: $id, input: $input) {\n        id\n        about\n      }\n    }\n  "): (typeof documents)["\n    mutation UserAboutScreen_UpdateUser($id: Int!, $input: UpdateUserInput!) {\n      updateUser(id: $id, input: $input) {\n        id\n        about\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query UserNameScreen_UserQuery($id: Int!) {\n      user(id: $id) {\n        id\n        firstName\n        lastName\n      }\n    }\n  "): (typeof documents)["\n    query UserNameScreen_UserQuery($id: Int!) {\n      user(id: $id) {\n        id\n        firstName\n        lastName\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation UserNameScreen_UpdateUser($id: Int!, $input: UpdateUserInput!) {\n      updateUser(id: $id, input: $input) {\n        id\n        fullName\n        firstName\n        lastName\n      }\n    }\n  "): (typeof documents)["\n    mutation UserNameScreen_UpdateUser($id: Int!, $input: UpdateUserInput!) {\n      updateUser(id: $id, input: $input) {\n        id\n        fullName\n        firstName\n        lastName\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment UserPersonalInfoScreen_User on User {\n    id\n    firstName\n    lastName\n    fullName\n    about\n    avatarUrl\n    profileFillPercentage\n\n    ...ContactFields\n  }\n"): (typeof documents)["\n  fragment UserPersonalInfoScreen_User on User {\n    id\n    firstName\n    lastName\n    fullName\n    about\n    avatarUrl\n    profileFillPercentage\n\n    ...ContactFields\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation UpdateCurrentUserAvatar(\n      $id: Int!\n      $input: UpdateUserAvatarInput!\n    ) {\n      updateUserAvatar(id: $id, input: $input) {\n        id\n        avatarUrl\n      }\n    }\n  "): (typeof documents)["\n    mutation UpdateCurrentUserAvatar(\n      $id: Int!\n      $input: UpdateUserAvatarInput!\n    ) {\n      updateUserAvatar(id: $id, input: $input) {\n        id\n        avatarUrl\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query UserPersonalInfoScreenGroup_UserQuery($id: Int!) {\n      user(id: $id) {\n        id\n        ...UserPersonalInfoScreen_User\n        ...ContactFields\n      }\n    }\n  "): (typeof documents)["\n    query UserPersonalInfoScreenGroup_UserQuery($id: Int!) {\n      user(id: $id) {\n        id\n        ...UserPersonalInfoScreen_User\n        ...ContactFields\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation UserPersonalInfoScreenGroup_UpdateUser(\n      $id: Int!\n      $input: UpdateUserInput!\n    ) {\n      updateUser(id: $id, input: $input) {\n        id\n        ...ContactFields\n      }\n    }\n  "): (typeof documents)["\n    mutation UserPersonalInfoScreenGroup_UpdateUser(\n      $id: Int!\n      $input: UpdateUserInput!\n    ) {\n      updateUser(id: $id, input: $input) {\n        id\n        ...ContactFields\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation Profile_DeleteUser($id: Int!) {\n      deleteUser(id: $id)\n    }\n  "): (typeof documents)["\n    mutation Profile_DeleteUser($id: Int!) {\n      deleteUser(id: $id)\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PartnerGroupedServicePreview_PartnerServiceFragment on PartnerService {\n    id\n    ...PartnerServicePreviewList_PartnerServiceFragment\n  }\n"): (typeof documents)["\n  fragment PartnerGroupedServicePreview_PartnerServiceFragment on PartnerService {\n    id\n    ...PartnerServicePreviewList_PartnerServiceFragment\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PartnerGroupedServicePreviewList_PartnerServiceFragment on PartnerService {\n    id\n    service {\n      id\n      specialization {\n        id\n        name\n      }\n    }\n    parameters {\n      id\n    }\n    ...PartnerGroupedServicePreview_PartnerServiceFragment\n  }\n"): (typeof documents)["\n  fragment PartnerGroupedServicePreviewList_PartnerServiceFragment on PartnerService {\n    id\n    service {\n      id\n      specialization {\n        id\n        name\n      }\n    }\n    parameters {\n      id\n    }\n    ...PartnerGroupedServicePreview_PartnerServiceFragment\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PartnerServicePreview_PartnerServiceFragment on PartnerService {\n    id\n    price\n    duration\n    description\n    isLiked\n    attachedPhotos {\n      id\n      url\n    }\n    service {\n      id\n      slug\n      name\n    }\n    masters {\n      id\n      slug\n    }\n    company {\n      id\n      slug\n    }\n    parameters {\n      id\n    }\n  }\n"): (typeof documents)["\n  fragment PartnerServicePreview_PartnerServiceFragment on PartnerService {\n    id\n    price\n    duration\n    description\n    isLiked\n    attachedPhotos {\n      id\n      url\n    }\n    service {\n      id\n      slug\n      name\n    }\n    masters {\n      id\n      slug\n    }\n    company {\n      id\n      slug\n    }\n    parameters {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation MasterServicesTab_UpdatePartnerServiceLikedStateMutation(\n      $id: Int!\n      $newIsLiked: Boolean!\n    ) {\n      updatePartnerServiceLikedState(id: $id, newIsLiked: $newIsLiked) {\n        id\n        isLiked\n      }\n    }\n  "): (typeof documents)["\n    mutation MasterServicesTab_UpdatePartnerServiceLikedStateMutation(\n      $id: Int!\n      $newIsLiked: Boolean!\n    ) {\n      updatePartnerServiceLikedState(id: $id, newIsLiked: $newIsLiked) {\n        id\n        isLiked\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation MasterServicesTab_AddMasterServiceToCartMutation(\n      $partnerServiceId: Int!\n      $workPlaceId: Int!\n      $input: AddServiceToCartInput!\n    ) {\n      addPartnerServiceToCart(\n        partnerServiceId: $partnerServiceId\n        workPlaceId: $workPlaceId\n        input: $input\n      ) {\n        id\n      }\n    }\n  "): (typeof documents)["\n    mutation MasterServicesTab_AddMasterServiceToCartMutation(\n      $partnerServiceId: Int!\n      $workPlaceId: Int!\n      $input: AddServiceToCartInput!\n    ) {\n      addPartnerServiceToCart(\n        partnerServiceId: $partnerServiceId\n        workPlaceId: $workPlaceId\n        input: $input\n      ) {\n        id\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PartnerServicePreviewList_PartnerServiceFragment on PartnerService {\n    id\n    parameters {\n      id\n    }\n    ...PartnerServicePreview_PartnerServiceFragment\n  }\n"): (typeof documents)["\n  fragment PartnerServicePreviewList_PartnerServiceFragment on PartnerService {\n    id\n    parameters {\n      id\n    }\n    ...PartnerServicePreview_PartnerServiceFragment\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query PartnerService_MasterQuery(\n      $masterSlug: String!\n      $serviceSlug: String!\n    ) {\n      master(slug: $masterSlug) {\n        id\n        service(slug: $serviceSlug) {\n          id\n        }\n      }\n    }\n  "): (typeof documents)["\n    query PartnerService_MasterQuery(\n      $masterSlug: String!\n      $serviceSlug: String!\n    ) {\n      master(slug: $masterSlug) {\n        id\n        service(slug: $serviceSlug) {\n          id\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query PartnerService_CompanyQuery(\n      $companySlug: String!\n      $serviceSlug: String!\n    ) {\n      company(slug: $companySlug) {\n        id\n      }\n    }\n  "): (typeof documents)["\n    query PartnerService_CompanyQuery(\n      $companySlug: String!\n      $serviceSlug: String!\n    ) {\n      company(slug: $companySlug) {\n        id\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation PartnerService_UpdatePartnerServiceLikedState(\n      $id: Int!\n      $newIsLiked: Boolean!\n    ) {\n      updatePartnerServiceLikedState(id: $id, newIsLiked: $newIsLiked) {\n        id\n        isLiked\n      }\n    }\n  "): (typeof documents)["\n    mutation PartnerService_UpdatePartnerServiceLikedState(\n      $id: Int!\n      $newIsLiked: Boolean!\n    ) {\n      updatePartnerServiceLikedState(id: $id, newIsLiked: $newIsLiked) {\n        id\n        isLiked\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query Services_SpecializationQuery($slug: String!) {\n      specialization(slug: $slug) {\n        id\n        name\n        slug\n        masterCount\n        companyCount\n\n        services {\n          id\n          slug\n          name\n        }\n      }\n    }\n  "): (typeof documents)["\n    query Services_SpecializationQuery($slug: String!) {\n      specialization(slug: $slug) {\n        id\n        name\n        slug\n        masterCount\n        companyCount\n\n        services {\n          id\n          slug\n          name\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ContactFields on ContactEntity {\n    phone\n    email\n    instagramProfile\n    telegramProfile\n    whatsappProfile\n    youtubeProfile\n    vkProfile\n    odnoklassnikiProfile\n    personalSite\n  }\n"): (typeof documents)["\n  fragment ContactFields on ContactEntity {\n    phone\n    email\n    instagramProfile\n    telegramProfile\n    whatsappProfile\n    youtubeProfile\n    vkProfile\n    odnoklassnikiProfile\n    personalSite\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SocialFields on ContactEntity {\n    instagramProfile\n    telegramProfile\n    whatsappProfile\n    youtubeProfile\n    vkProfile\n    odnoklassnikiProfile\n    personalSite\n  }\n"): (typeof documents)["\n  fragment SocialFields on ContactEntity {\n    instagramProfile\n    telegramProfile\n    whatsappProfile\n    youtubeProfile\n    vkProfile\n    odnoklassnikiProfile\n    personalSite\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query AppLayoutCatalogMenu_SpecializationsQuery {\n      specializations {\n        id\n        slug\n        name\n        masterCount\n        companyCount\n        services {\n          id\n          slug\n          name\n        }\n      }\n    }\n  "): (typeof documents)["\n    query AppLayoutCatalogMenu_SpecializationsQuery {\n      specializations {\n        id\n        slug\n        name\n        masterCount\n        companyCount\n        services {\n          id\n          slug\n          name\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query AppLayoutHeader_UserQuery($id: Int!) {\n      user(id: $id) {\n        id\n        avatarUrl\n\n        ...AppLayoutProfilePopover_UserFragment\n      }\n    }\n  "): (typeof documents)["\n    query AppLayoutHeader_UserQuery($id: Int!) {\n      user(id: $id) {\n        id\n        avatarUrl\n\n        ...AppLayoutProfilePopover_UserFragment\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment AppLayoutProfilePopover_UserFragment on User {\n    id\n    fullName\n    avatarUrl\n  }\n"): (typeof documents)["\n  fragment AppLayoutProfilePopover_UserFragment on User {\n    id\n    fullName\n    avatarUrl\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;